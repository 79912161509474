.Main-Part{
  background-color:  whitesmoke;
  color: rgb(134, 134, 134);
  margin-left:0%;
  margin-right: 0%;
  padding-top: 0%;
  padding-bottom: 40px;
  }
  @media (min-width: 768px)
  {
    .mx-md-5
    {
      margin-right:15% !important;
      margin-left:15% !important;
    } 
  }
.h1
{
  padding-top: 0.5cm
}
.caption
{
  color: rgb(150 , 150, 150);
}
.BG_whitesmoke{
  background-color:  rgb(253, 253, 253);
}
/*CONTAINER (GRID), ROWS, COLS*/
.lastRowSection{
  padding-bottom: 0.5cm;
}
/*BUTTON PRIMARY*/
.btn-primary {
  background-color: rgb(151, 39, 53);
  color: white;
  border: none;
  
}
.btn-primary:hover {
  background-color: rgb(82, 21, 28);
  color: white;
}
.btn-primary:active {
  background-color: rgb(43, 11, 15) !important;
  color: white;
  
  outline-color: rgb(151, 39, 53);
}
.btn-primary:focus {
  background-color:  rgb(82, 21, 28) !important;
  box-shadow: 0 0 0 0.2rem  rgb(151, 39, 53) !important;
  border-color: rgb(151, 39, 53) !important;
  color: white;
}
/*BUTTON XXL*/
.btn-xxl {
  padding: 1rem 1.5rem;
  font-size: 1.5rem;
}
/*BUTTON XL*/
.btn-xl {
  padding: 0.75rem 1.25rem;
  font-size: 1.25rem;
}
.img-fluid
{
  height: 180px;
}