.App {
  text-align: center;
  min-height:100vh;
}

.App-logo {
  height: 45px;
  padding-right: 8px;
  pointer-events: none;
}
.App-logo2 {
  height: 20px;
  padding-left: 8px;
  padding-right: 8px;
  pointer-events: none;
}
.App-header {
  background-color: rgb(151, 39, 53);
  align-items: center;
  font-size: 23px;
  color:  rgba(255, 255, 255,.6) !important;
  min-height: 40px;
}
.App-header:hover {
  color: rgb(216, 216, 216) !important;
}
.dropdown-toggle::after {
  color: rgba(255, 255, 255,.6);
}
.dropdown-toggle:hover::after {
  color: rgb(216, 216, 216);
}
.dropdown-item:active{
  background-color: rgb(151, 39, 53);
}
.Justify-content-end{
  justify-content: flex-end;
  color: white !important;
}
.App-footer {
  background-color: rgb(151, 39, 53);
  width:100%;
  margin-bottom: 0px;
  font-size: 15px;
  color: rgba(255, 255, 255,.6) !important;
  /*Has to match with MainFrame:mainPart:padding-bottom;*/
  height: 40px;
  margin-top: -40px;
}
/*For the footer*/
.wrapper
{
  min-height: 100vh;
}
.footerPad{
  padding-top: 8px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
